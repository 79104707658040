import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useTokenData } from 'Auth/currentUser'
import SignIn from 'Auth/SignIn'
// import SignUp from 'Auth/SignUp'
import Layout from 'Layout'
import UserManagementList from 'Admin/UserManagement/List'
import UserManagementForm from 'Admin/UserManagement/Form'
import Home from 'User/Home'
import routes from 'routes'
import OrderForms from 'User/Forms/OrderForms'
import InstallationGuides from 'User/Forms/InstallationGuides'
import Contacts from 'User/Contacts'

const AuthRoutes = () => (
  <Switch>
    <Route path={routes.signIn()} exact component={SignIn} />
    {/*<Route path={routes.signUp()} exact component={SignUp} />*/}
    <Redirect to={routes.signIn()} />
  </Switch>
)

const AdminRoutes = () => (
  <Layout>
    <Switch>
      <Route
        path={routes.userManagement.list()}
        exact
        component={UserManagementList}
      />
      <Route
        path={routes.userManagement.new()}
        exact
        component={UserManagementForm}
      />
      <Route
        path={routes.userManagement.edit()}
        exact
        component={UserManagementForm}
      />
      <Redirect to={routes.root()} />
    </Switch>
  </Layout>
)

const UserRoutes = () => (
  <Layout>
    <Switch>
      <Route exact path={routes.root()} component={Home} />
      <Route exact path={routes.forms.orderForms()} component={OrderForms} />
      <Route
        exact
        path={routes.forms.installationGuides()}
        component={InstallationGuides}
      />
      {/* <Route exact path={routes.contacts()} component={Contacts} /> */}
      <Redirect to={routes.root()} />
    </Switch>
  </Layout>
)

export default function Routes() {
  const token = useTokenData()

  return (
    <>
      {!token.signedIn && <AuthRoutes />}
      {token.signedIn && (
        <>
          {token?.type === 'admin' && <AdminRoutes />}
          {token?.type === 'user' && <UserRoutes />}
        </>
      )}
    </>
  )
}
