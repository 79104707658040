import React from 'react'
import { useGet } from 'fetch'
import api from 'api'
import { useTokenData } from 'Auth/currentUser'
import { User } from 'User/types'
import Nav from 'User/Nav'
import dayjs from 'dayjs'

const fields: Record<
  keyof Omit<User, 'password' | 'id'>,
  {
    label?: string
    type?: 'date' | 'references' | 'personnel'
  }
> = {
  email: { label: 'Email' },
  dealerCode: { label: 'Dealer Code' },
  businessName: { label: 'Business Name' },
  businessType: { label: 'Business Type' },
  businessEstablishedYear: { label: 'Year established' },
  dealerApprovedDate: { label: 'Dealer Approved Date', type: 'date' },
  businessActivities: { label: 'Primary Business Activities' },
  street: { label: 'Street Address' },
  state: { label: 'State' },
  city: { label: 'City' },
  zip: { label: 'Zip' },
  mainPointOfContact: { label: 'Main Point of Contact' },
  phone: { label: 'Phone' },
  mobile: { label: 'Mobile' },
  website: { label: 'Website' },
  personnel: { type: 'personnel', label: 'Approved Personnel' },
  monthlyBlindSales: {
    label: 'What are you projected monthly blind sales',
  },
}

const groups: (keyof Omit<User, 'password' | 'id'>)[][] = [
  [
    'email',
    'dealerCode',
    'businessName',
    'businessType',
    'businessEstablishedYear',
    'dealerApprovedDate',
    'businessActivities',
    'street',
    'state',
    'city',
    'zip',
    'mainPointOfContact',
    'phone',
    'mobile',
    'website',
    'personnel',
    'monthlyBlindSales',
  ],
]

export default function Home() {
  const token = useTokenData()
  const { isLoading, data: user } = useGet<User>(api.user.get(token.id))

  if (isLoading || !user) return null

  const data = groups.map((fieldNames) => {
    return fieldNames.map((name) => {
      const { label, type } = fields[name]
      const value = user[name]

      if (!value || (Array.isArray(value) && value.length === 0)) return null

      return [
        label || name,
        !type ? (
          value
        ) : type === 'date' ? (
          dayjs((value as unknown) as string).format('M/D/YYYY')
        ) : type === 'references' ? (
          <ol className="list-decimal pl-4">
            {((value as unknown) as string[]).map((ref) => (
              <li className="text-gray-600 mt-1">{ref}</li>
            ))}
          </ol>
        ) : type === 'personnel' ? (
          <ol className="list-decimal pl-4">
            {(value as { label: string; email: string }[]).map(
              ({ label, email }) => (
                <li className="text-gray-600 mt-1">
                  {label}{' '}
                  <a href={`mailto:${email}`} className="text-blue-primary">
                    {email}
                  </a>
                </li>
              ),
            )}
          </ol>
        ) : null,
      ]
    })
  })

  return (
    <>
      <div className="header px-2 md:px-5 lg:px-10">
        <div className="title">Account Info</div>
      </div>
      <Nav />
      <div className="py-5 px-2 md:px-5 lg:px-10 pt-0">
        <div className="border p-3">
          <div className="hidden sm:block">
            <table className="table table-px-4">
              <tbody>
                {data.map((rows, i) => (
                  <React.Fragment key={i}>
                    {rows.map((row, i) => (
                      <React.Fragment key={i}>
                        {row && (
                          <tr key={i}>
                            <td
                              className="uppercase font-bold text-gray-600"
                              style={{ width: '300px' }}
                            >
                              {row[0]}
                            </td>
                            <td className="text-gray-500">{row[1]}</td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <div className="sm:hidden text-lg">
            {data.map((rows, i) => (
              <React.Fragment key={i}>
                {rows.map((row, i) => (
                  <React.Fragment key={i}>
                    {row && (
                      <div className="mb-4">
                        <div className="font-bold text-gray-600">{row[0]}</div>
                        <div className="text-gray-500">{row[1]}</div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
