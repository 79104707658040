import { Controller, UseFormMethods } from 'react-hook-form'
import { X } from '@styled-icons/boxicons-regular/X'
import React from 'react'

type Props = {
  form: UseFormMethods<any>
}

export default function Personnel({ form }: Props) {
  const { control } = form

  return (
    <Controller
      control={control}
      name="personnel[]"
      render={({ value, onChange, onBlur }) => {
        const values = value as { label: string; email: string }[]

        return (
          <div>
            {values.map((item, index) => (
              <div className="mb-4 flex items-center" key={index}>
                <input
                  name="personnel[]label"
                  placeholder="Full Name"
                  value={item.label}
                  onChange={(e) => {
                    const arr = [...values]
                    arr[index] = { ...item, label: e.target.value }
                    onChange(arr)
                  }}
                  onBlur={onBlur}
                  className="form-input mr-4"
                />
                <input
                  name="personnel[]email"
                  type="email"
                  placeholder="Email"
                  value={item.email}
                  onChange={(e) => {
                    const arr = [...values]
                    arr[index] = { ...item, email: e.target.value }
                    onChange(arr)
                  }}
                  onBlur={onBlur}
                  className="form-input"
                />
                <div
                  className="ml-3 focus:outline-none"
                  onClick={() => {
                    const arr = [...values]
                    arr.splice(index, 1)
                    onChange(arr)
                  }}
                >
                  <X size={24} className="text-red-500" />
                </div>
              </div>
            ))}
            <div
              className="btn btn-small btn-primary cursor-pointer inline-flex"
              onClick={() => onChange([...values, ''])}
            >
              Add Person
            </div>
          </div>
        )
      }}
    />
  )
}
