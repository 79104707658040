export default {
  auth: () => `/auth`,
  user: {
    get: (id: number) => `/users/${id}`,
    create: () => '/users',
    update: (id: number) => `/users/${id}`,
    list: () => '/users',
    remove: (id: number) => `/users/${id}`,
  },
}
