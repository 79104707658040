export default {
  root: () => '/',
  signIn: () => '/sign-in',
  signUp: () => '/sign-up',
  forgotPassword: () => '/forgot-password',
  userManagement: {
    list: () => '/',
    new: () => '/users/new',
    edit: (id?: number) => `/users/${id || ':id'}`,
  },
  forms: {
    index: () => '/forms',
    orderForms: () => '/forms/order-forms',
    installationGuides: () => '/forms/installation-guides',
    sampleForm: () => '/forms/sample-form',
  },
  // contacts: () => '/contacts',
}
