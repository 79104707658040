import React from 'react'
import { useForm } from 'Shared/Form'
import * as yup from 'yup'
import { Input } from 'Shared/Form/Input'
import Personnel from './Personnel'
import { patch, post, useGet, useRequest } from 'fetch'
import api from 'api'
import history from 'Shared/history'
import { useNotifications } from 'Shared/notifications'
import { User } from 'User/types'
import { useParams } from 'react-router-dom'
import routes from 'routes'
import dayjs from 'dayjs'

const fields = {
  email: yup.string().required(),
  dealerCode: yup.string(),
  businessName: yup.string().required().label('Business Name'),
  businessType: yup.string(),
  businessEstablishedYear: yup.number(),
  businessActivities: yup.string(),
  dealerApprovedDate: yup.string(),
  street: yup.string(),
  city: yup.string(),
  state: yup.string(),
  zip: yup.string(),
  mainPointOfContact: yup.string(),
  phone: yup.string(),
  mobile: yup.string(),
  website: yup.string(),
  personnel: yup.array(),
  monthlyBlindSales: yup.string(),
}

const updateSchema = yup.object(fields)

const createSchema = yup.object({
  ...fields,
  password: yup.string().required(),
})

export default function UserManagementForm() {
  const params = useParams<{ id?: string }>()
  const id = params.id ? parseInt(params.id) : 0
  const { isLoading, data: user } = useGet<User>(api.user.get(id), {
    enabled: id,
    cacheTime: 0,
  })

  if (id && isLoading) return null
  if (user) {
    delete user.password
    for (const key in user)
      if (user[key as keyof User] === null) delete user[key as keyof User]
  }

  return (
    <UserManagementFormForUser
      user={
        user || {
          personnel: [],
          dealerApprovedDate: dayjs().format('YYYY-MM-DD'),
        }
      }
      createUser={!user}
    />
  )
}

function UserManagementFormForUser({
  user,
  createUser,
}: {
  user: Partial<User>
  createUser: boolean
}) {
  const form = useForm({
    schema: createUser ? createSchema : updateSchema,
    defaultValues: user,
  })

  const { setNotifications } = useNotifications()

  const [save, { isLoading, error }] = useRequest<{ token: string }>(
    createUser ? post : patch,
    createUser ? api.user.create() : api.user.update(user.id as number),
    {
      onSuccess() {
        const location = routes.userManagement.list()
        setNotifications([
          {
            type: 'success',
            message: createUser ? 'Dealer created' : 'Dealer updated',
            location,
          },
        ])
        history.goBack()
      },
    },
  )

  React.useEffect(() => {
    if (error)
      setNotifications([
        {
          type: 'danger',
          message: error.message,
          location: history.location.pathname,
        },
      ])
  }, [error])

  const submit = (values: Omit<User, 'id'>) => {
    if (isLoading) return
    save(values)
  }

  return (
    <form onSubmit={form.handleSubmit(submit)}>
      <div className="header px-2 md:px-5 lg:px-10">
        <div className="title">
          {createUser ? 'Create new' : 'Update'} Dealer
        </div>
      </div>
      <div className="py-10 px-2 md:px-5 lg:px-10">
        <div className="flex flex-wrap mb-5">
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            type="email"
            name="email"
            label="Email"
            placeholder="Email"
          />
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            type="password"
            name="password"
            label="Password"
            placeholder="Password"
          />
        </div>
        <div className="flex flex-wrap mb-5">
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            name="dealerCode"
            label="Dealer Code"
            placeholder="Dealer Code"
          />
        </div>
        <div className="flex flex-wrap mb-5">
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            name="businessName"
            label="Business Name"
            placeholder="Business Name"
          />
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            name="businessType"
            label="Business Type"
            placeholder="Business Type"
          />
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            type="number"
            name="businessEstablishedYear"
            label="Year Established"
            placeholder="Year Established"
          />
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            type="date"
            name="dealerApprovedDate"
            label="Dealer Approved Date"
          />
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            name="businessActivities"
            label="Primary Business Activities"
            placeholder="Primary Business Activities"
          />
        </div>
        <div className="flex flex-wrap mb-5">
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            name="street"
            label="Street Address"
            placeholder="Street Address"
          />
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            name="city"
            label="City"
            placeholder="City"
          />
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            name="state"
            label="State"
            placeholder="State"
          />
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            name="zip"
            label="Zip"
            placeholder="Zip"
          />
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            name="mainPointOfContact"
            label="Main Point of Contact"
            placeholder="Main Point of Contact"
          />
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            name="phone"
            label="Phone"
            placeholder="Phone"
          />
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            name="mobile"
            label="Mobile"
            placeholder="Mobile"
          />
          <Input
            className="mb-5 mr-5"
            inputClassName="w-64"
            form={form}
            name="website"
            label="Website"
            placeholder="Website"
          />
        </div>
        <div className="mb-5">
          <div className="text-lg text-blue-dark uppercase mb-4">
            Approved Personnel
          </div>
          <Personnel form={form} />
        </div>
        <Input
          className="mb-5"
          inputClassName="w-64"
          form={form}
          name="monthlyBlindSales"
          label="What are you projected monthly blind sales"
        />
      </div>
      <div className="header">
        <div className="w-full flex-center">
          <button className="btn btn-primary" disabled={isLoading}>
            Submit
          </button>
        </div>
      </div>
    </form>
  )
}
