import { useLocalStorage } from 'Shared/localStorage'
import parseJWT from 'Shared/parseJWT'

const tokenName = 'authToken'

export const useToken = (): ReturnType<typeof useLocalStorage> =>
  useLocalStorage(tokenName)

export const getToken = () => localStorage.getItem(tokenName)

export const useTokenData = (): {
  signedIn: boolean
  id: number
  email: string
  type: 'admin' | 'user'
} => {
  const [token] = useToken()
  return (
    (token && { ...parseJWT(token), signedIn: true }) || {
      id: 0,
      email: '',
      type: '',
      signedIn: false,
    }
  )
}
