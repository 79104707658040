import React from 'react'
import { useToken, useTokenData } from 'Auth/currentUser'
import { Link } from 'react-router-dom'
import routes from 'routes'
import { useNotifications } from 'Shared/notifications'
import logo from 'assets/images/logo.png'
import style from './style.module.css'
import cn from 'classnames'

type Props = {
  children: React.ReactNode
}

const adminMenu = [
  {
    label: 'User Management',
    link: routes.userManagement.list(),
  },
]

export default function Layout({ children }: Props) {
  const [, setToken] = useToken()
  const { email, type } = useTokenData() || { email: '', type: '' }
  const { notifications, removeNotification } = useNotifications()
  const checkboxRef = React.useRef(null)

  return (
    <div className="w-full flex flex-col px-2 sm:px-4 md:px-5">
      <div
        className="mx-auto w-full relative border-b border-blue-primary"
        style={{
          maxWidth: '1300px',
        }}
      >
        <div className="flex justify-center bg-white h-0 md:block">
          <div className="md:absolute top-0 md:right-0 md:mr-4 text-white bg-blue-primary rounded-bl-xl rounded-br-xl h-8 flex-center px-4 z-20">
            {email}
          </div>
        </div>
        <div
          className="bg-white w-full flex items-end justify-end relative bg-center bg-no-repeat pt-20 z-10 h-48 md:h-32"
          style={{
            backgroundImage: `url(${logo})`,
            backgroundSize: '230px 100px',
          }}
        >
          <div className="md:h-full py-2 px-10 flex items-center justify-center md:justify-end w-full">
            {type === 'admin' && (
              <div className="justify-between w-full hidden lg:flex">
                <div>
                  {adminMenu.map(({ label, link }, i) => (
                    <Link
                      key={i}
                      to={link}
                      className="uppercase text-blue-dark font-bold transition duration-200 hover:text-gray-dark mr-4"
                    >
                      {label}
                    </Link>
                  ))}
                </div>
                <button
                  type="button"
                  className="uppercase text-blue-dark font-bold transition duration-200 hover:text-gray-dark"
                  onClick={() => setToken('')}
                >
                  Sign out
                </button>
              </div>
            )}
            {type === 'user' && (
              <button
                type="button"
                className="uppercase text-blue-dark font-bold transition duration-200 hover:text-gray-dark"
                onClick={() => setToken('')}
              >
                Sign out
              </button>
            )}
          </div>
        </div>
        {type === 'admin' && (
          <>
            <input
              ref={checkboxRef}
              id="hamburgerMenu"
              type="checkbox"
              hidden
              className={style.hamburgerCheckbox}
            />
            <label
              htmlFor="hamburgerMenu"
              className={cn(
                'absolute top-0 right-0 mt-16 mr-5 block lg:hidden z-10',
                style.hamburgerLabel,
              )}
            >
              <span className={style.hamburgerLine} />
              <span className={style.hamburgerLine} />
              <span className={style.hamburgerLine} />
            </label>
            <div
              className={cn(
                'absolute left-0 right-0 -mt-px block lg:hidden',
                style.menu,
              )}
            >
              <div
                className={cn(
                  'bg-white pt-8 pb-4 flex flex-col items-center border-b border-blue-primary',
                  style.menuAppearing,
                )}
                onClick={(e) => {
                  if ((e.target as HTMLElement).tagName === 'A') {
                    ;(checkboxRef.current as any).checked = false
                  }
                }}
              >
                {adminMenu.map(({ label, link }, i) => (
                  <Link
                    key={i}
                    to={link}
                    className="uppercase text-blue-dark font-bold transition duration-200 hover:text-gray-dark h-8 flex-center"
                  >
                    {label}
                  </Link>
                ))}
                <button
                  type="button"
                  className="uppercase text-blue-dark font-bold transition duration-200 hover:text-gray-dark h-8 flex-center"
                  onClick={() => setToken('')}
                >
                  Sign out
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex-grow flex">
        <div
          className="mx-auto w-full bg-white content-background"
          style={{ maxWidth: '1300px' }}
        >
          {notifications.length > 0 && (
            <div className="bg-gray-100 px-5">
              {notifications.map((notification, i) => (
                <div
                  key={i}
                  className={`border py-2 px-3 mt-5 relative ${
                    notification.type === 'success'
                      ? 'border-green-300 bg-green-100 text-green-600'
                      : 'border-red-300 bg-red-100 text-red-600'
                  }`}
                >
                  {notification.message}
                  <button
                    className={`text-xl absolute top-0 right-0 bottom-0 px-3 ${
                      notification.type === 'success'
                        ? 'text-green-500'
                        : 'text-red-500'
                    }`}
                    onClick={() => removeNotification(notification)}
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}
