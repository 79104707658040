import React from 'react'
import { Link } from 'react-router-dom'
import routes from 'routes'
import { del, useGet } from 'fetch'
import api from 'api'
import { useLocation } from 'react-router-dom'
import { User } from 'User/types'
import cn from 'classnames'
import Modal from 'Shared/Modal'
import { queryCache, useMutation } from 'react-query'
import { Edit } from '@styled-icons/boxicons-regular/Edit'
import { Trash } from '@styled-icons/boxicons-regular/Trash'

export default function UserManagementList() {
  const { search } = useLocation()
  const urlParams = new URLSearchParams(search)
  const page = parseInt(urlParams.get('page') || '1')
  const limit = 10
  const listApiPath = `${api.user.list()}?limit=${limit}&offset=${
    (page - 1) * limit
  }`
  const { data } = useGet<{ list: User[]; count: number }>(listApiPath, {
    keepPreviousData: true,
  })
  const [userForDelete, setUserForDelete] = React.useState<User | null>(null)

  const [remove, { isLoading }] = useMutation(
    (id: number) => del(api.user.remove(id)),
    {
      onSuccess() {
        queryCache.invalidateQueries(listApiPath)
      },
    },
  )
  const removeUser = () => {
    if (isLoading || !userForDelete) return
    remove(userForDelete.id)
    setUserForDelete(null)
  }

  return (
    <div>
      {userForDelete && (
        <Modal onClose={() => setUserForDelete(null)}>
          <div className="rounded overflow-hidden bg-white">
            <div className="font-bold text-center py-5 border-b">
              Are you sure?
            </div>
            <div className="flex justify-end p-5 bg-white">
              <button
                className="btn btn-small btn-primary"
                onClick={() => setUserForDelete(null)}
              >
                Cancel
              </button>
              <button
                className="btn btn-small btn-primary ml-3"
                onClick={removeUser}
              >
                Delete dealer
              </button>
            </div>
          </div>
        </Modal>
      )}
      <div className="header px-2 md:px-5 lg:px-10">
        <div className="title">Dealers</div>
        <Link to={routes.userManagement.new()} className="btn btn-primary">
          Add User
        </Link>
      </div>
      <div className="py-10 px-2 md:px-5 lg:px-10">
        {data && (
          <>
            <div className="overflow-auto">
              <table className="table whitespace-no-wrap">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Dealer Code</th>
                    <th>Phone</th>
                    <th>Mobile</th>
                    <th>Website</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.list.map((user) => (
                    <tr key={user.id}>
                      <td>{user.businessName}</td>
                      <td>{user.email}</td>
                      <td>{user.dealerCode}</td>
                      <td>{user.phone}</td>
                      <td>{user.mobile}</td>
                      <td>{user.website}</td>
                      <td className="text-center">
                        <div className="inline-flex p-2">
                          <Link
                            to={routes.userManagement.edit(user.id)}
                            className="btn btn-primary btn-small"
                          >
                            <Edit size={24} />
                          </Link>
                          <button
                            className="btn btn-primary btn-small"
                            onClick={() => setUserForDelete(user)}
                          >
                            <Trash size={24} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex-center mt-3">
              {new Array(Math.ceil(data.count / limit))
                .fill(null)
                .map((_, i) => (
                  <Link
                    key={i}
                    to={`${routes.userManagement.list()}?page=${i + 1}`}
                    className={cn(
                      'btn btn-small btn-primary',
                      i + 1 === page && 'btn-filled',
                    )}
                  >
                    {i + 1}
                  </Link>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
