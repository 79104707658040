import React from 'react'
import bgImage from 'assets/images/bg.jpg'
import logo from 'assets/images/logo.png'

type Props = {
  children: React.ReactNode
  onSubmit(e: React.FormEvent<HTMLFormElement>): void
}

export default function Form({ children, onSubmit }: Props) {
  return (
    <div
      className="h-full bg-gray-200 flex-center"
      style={{
        background: `url("${bgImage}") no-repeat center center`,
        backgroundSize: 'cover',
      }}
    >
      <div
        className="max-h-full overflow-auto px-3"
        style={{ paddingTop: '60px', paddingBottom: '60px' }}
      >
        <form
          className="p-7 w-full rounded-lg relative z-10"
          style={{
            boxShadow: '4px 7px 27.55px 1.45px rgba(29, 52, 104, 0.5)',
            background: 'rgba(255,255,255,.85)',
            maxWidth: '470px',
          }}
          onSubmit={onSubmit}
        >
          <div className="flex-center mb-5">
            <img src={logo} alt="logo" style={{ height: '100px' }} />
          </div>
          <div
            className="font-title uppercase text-center text-gray-600 mb-3 text-3xl sm:text-5xl"
            style={{ lineHeight: 1 }}
          >
            Dealer Access Portal
          </div>
          {children}
        </form>
      </div>
      <div className="absolute left-0 bottom-0 ml-5 mb-5 text-white text-sm">
        <a
          href="https://poseidoninteriors.com"
          className="mr-3 hover:underline"
        >
          Back to Home
        </a>
        |
        <a
          href="https://poseidoninteriors.com/privacy"
          className="ml-3 hover:underline"
        >
          Privacy
        </a>
      </div>
    </div>
  )
}
