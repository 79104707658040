import React from 'react'
import * as yup from 'yup'
import { useForm, Input } from 'Shared/Form'
import { usePost } from 'fetch'
import api from 'api'
import { useToken } from 'Auth/currentUser'
import Form from 'Auth/Form'
import logo from 'assets/images/logo.png'

const signInSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
})

export default function SignIn() {
  const form = useForm({ schema: signInSchema })
  const [signIn, { isLoading, error, data }] = usePost<{ token: string }>(
    api.auth(),
  )
  const [, setToken] = useToken()

  React.useEffect(() => {
    if (data) setToken(data.token)
  }, [data])

  return (
    <Form onSubmit={form.handleSubmit((values) => signIn(values))}>
      <div className="text-center mb-4 text-gray-600">
        This content is password protected. To view it please enter your
        password below:
      </div>
      {error && <div className="form-error mb-2">{error.message}</div>}
      <div className="mb-4">
        <Input form={form} name="email" type="email" placeholder="Email" />
      </div>
      <div className="mb-4">
        <Input
          form={form}
          name="password"
          type="password"
          placeholder="Password"
        />
      </div>
      <div className="flex-center">
        <input
          className="bg-blue-82 rounded text-white flex-center h-12 w-full hover:bg-blue-66 cursor-pointer uppercase border border-blue-66"
          type="submit"
          value="Login"
          disabled={isLoading}
          style={{ borderWidth: '3px' }}
        />
        {/*<Link className="btn btn-primary-outline" to={routes.signUp()}>*/}
        {/*  Sign Up*/}
        {/*</Link>*/}
      </div>
      <div className="text-gray-600 mt-6 text-center text-sm">
        For Support or to Become a Poseidon Interiors Dealer, please email{' '}
        <a
          href="mailto:contact@poseidoninteriors.com"
          className="text-gray-700"
        >
          contact@poseidoninteriors.com
        </a>
        <br />
        or call{' '}
        <a href="tel:+1-954-589-1926" className="text-gray-700">
          +1-954-589-1926
        </a>
      </div>
    </Form>
  )
}
