import React from 'react'

type Props = {
  onClose(): void
  children: React.ReactNode
}

export default function Modal({ onClose, children }: Props) {
  const ref = React.useRef(null)

  React.useEffect(() => {
    const listener = (e: any) => {
      if (e.target === ref.current) onClose()
    }
    window.addEventListener('mousedown', listener)
    return () => window.removeEventListener('mousedown', listener)
  }, [])

  return (
    <div
      ref={ref}
      className="fixed top-0 left-0 right-0 bottom-0 flex-center z-50"
      style={{ backdropFilter: 'blur(1px)', background: 'rgba(0, 0, 0, .3)' }}
    >
      {children}
    </div>
  )
}
