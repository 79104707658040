import React from 'react'
import Nav from 'User/Nav'
import DownloadItem from 'User/Forms/DownloadItem'

const forms: { name: string; pdf: string }[] = [
  {
    name: 'Programming Instructions for Poseidon Motors',
    pdf:
      '/installation_guides/Programming Instructions for Poseidon Motors.pdf',
  },
  {
    name: 'Programming Instructions for Motorized Wood and HC',
    pdf:
      '/installation_guides/Programming Instructions for Motorized Wood and HC.pdf',
  },
  {
    name: 'Poseidon Skyline RS Installation',
    pdf: '/installation_guides/Poseidon Skyline RS Installation.pdf',
  },
  {
    name: 'Poseidon Dummy Motor Instructions',
    pdf: '/installation_guides/Poseidon Dummy Motor Instructions.pdf',
  },
  {
    name: 'Poseidon 1 inch 25mm Headrail Installation',
    pdf: '/installation_guides/Poseidon 1 inch 25mm Headrail Installation.pdf',
  },
  {
    name: 'Install Instructions for Standard Harbour.Oceanic.Atlantis',
    pdf:
      '/installation_guides/Install Instructions for Standard Harbour.Oceanic.Atlantis.pdf',
  },
  {
    name: 'Install Instructions for Standard Cordless Coral Honeycomb',
    pdf:
      '/installation_guides/Install Instructions for Standard Cordless Coral Honeycomb.pdf',
  },
  {
    name: 'Install Instructions for Rectangular Tension HC & Pleated',
    pdf:
      '/installation_guides/Install Instructions for Rectangular Tension HC & Pleated.pdf',
  },
  {
    name: 'Install Instructions for Motorized Ocean Sheer',
    pdf:
      '/installation_guides/Install Instructions for Motorized Ocean Sheer.pdf',
  },
  {
    name: 'Install Instructions for Cordless Regatta RS',
    pdf:
      '/installation_guides/Install Instructions for Cordless Regatta RS.pdf',
  },
  {
    name: 'Install Instructions for Cordless Coral HC - Magnets',
    pdf:
      '/installation_guides/Install Instructions for Cordless Coral HC - Magnets.pdf',
  },
  {
    name: 'Spanish Programming Instructions for Poseidon Motors',
    pdf:
      '/installation_guides/Spanish Programming Instructions for Poseidon Motors.pdf',
  },
  {
    name: 'Spanish Programming Instructions for Motorized Wood and HC',
    pdf:
      '/installation_guides/Spanish Programming Instructions for Motorized Wood and HC.pdf',
  },
]

export default function Forms() {
  return (
    <>
      <div className="header px-2 md:px-5 lg:px-10">
        <div className="title">Installation Guides</div>
      </div>
      <Nav />
      <div className="py-5 px-2 md:px-5 lg:px-10 pt-5">
        {forms.map(({ name, pdf }, index) => (
          <DownloadItem
            index={index}
            name={name}
            pdf={pdf}
            className="max-w-3xl"
          />
        ))}
      </div>
    </>
  )
}
