import React from 'react'
import cn from 'classnames'

type Props = {
  index: number
  name: string
  excel?: string
  pdf: string
  className?: string
}

export default function DownloadItem({
  name,
  excel,
  pdf,
  index,
  className,
}: Props) {
  return (
    <div
      className={cn(
        'pt-5 mb-5 mx-auto flex flex-col items-center md:flex-row',
        className,
        index !== 0 && 'border-t',
      )}
    >
      <div className="text-gray-700 text-2xl sm:text-3xl font-title mb-2 md:mb-0 md:w-full text-center md:text-left">
        {name}
      </div>
      <div className="flex">
        {excel && (
          <a
            href={excel}
            target="_blank"
            className="btn h-10 sm:h-12 px-4 sm:px-6 md:w-full border-green-600 text-green-600 bg-white hover:border-gray-dark hover:bg-gray-dark hover:text-white"
          >
            Excel
          </a>
        )}
        <a
          href={pdf}
          target="_blank"
          className="btn h-10 sm:h-12 px-4 sm:px-6 md:w-full border-red-600 text-red-600 bg-white hover:border-gray-dark hover:bg-gray-dark hover:text-white ml-5"
        >
          PDF
        </a>
      </div>
    </div>
  )
}
