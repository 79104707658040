import React from 'react'
import { Switch, Route, NavLink } from 'react-router-dom'
import routes from 'routes'

const className =
  'w-1/2 text-white border-blue-dark uppercase border h-12 p-2 flex-center font-bold'
const activeClass = 'bg-blue-dark'
const subNavClass =
  'btn-primary py-2 border border-blue-primary rounded w-56 font-bold uppercase text-center text-sm px-2 sm:mx-1 md:mx-4 my-2 sm:my-0'

export default function Nav() {
  return (
    <div className="px-2 md:px-5 lg:px-10">
      <div className="flex w-full mt-5 mb-4 bg-blue-primary">
        <NavLink
          exact
          to={routes.root()}
          className={className}
          activeClassName={activeClass}
        >
          Profile
        </NavLink>
        <NavLink
          exact
          to={routes.forms.orderForms()}
          className={className}
          activeClassName={activeClass}
        >
          Forms
        </NavLink>
        {/* <NavLink
          exact
          to={routes.contacts()}
          className={className}
          activeClassName={activeClass}
        >
          Support
        </NavLink> */}
      </div>
      <Switch>
        <Route
          path={routes.forms.index()}
          render={() => (
            <div className="mt-5 sm:mt-10 sm:mb-2 flex flex-col sm:flex-row items-center justify-center">
              <NavLink
                exact
                to={routes.forms.orderForms()}
                className={subNavClass}
                activeClassName="text-gray-dark"
              >
                Order Forms
              </NavLink>
              <NavLink
                exact
                to={routes.forms.installationGuides()}
                className={subNavClass}
                activeClassName="text-gray-dark"
              >
                Installation Guides
              </NavLink>
              {/*<NavLink*/}
              {/*  exact*/}
              {/*  to={routes.forms.sampleForm()}*/}
              {/*  className={subNavClass}*/}
              {/*  activeClassName="text-gray-dark"*/}
              {/*>*/}
              {/*  Sample Order Form*/}
              {/*</NavLink>*/}
            </div>
          )}
        />
      </Switch>
    </div>
  )
}
