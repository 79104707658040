import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import history from 'Shared/history'
import { NotificationsContextProvider } from 'Shared/notifications'
import Routes from 'Route'
import './generated.css'
import './style.css'

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <NotificationsContextProvider>
        <Routes />
      </NotificationsContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
)
