import React from 'react'
import Nav from 'User/Nav'
import DownloadItem from 'User/Forms/DownloadItem'

const forms: { name: string; pdf: string; excel: string }[] = [
  {
    name: 'Atlantis Roman Form',
    pdf: '/forms/M Atlantis Roman Production Form 1.5.pdf',
    excel: '/forms/M Atlantis Roman Production Form 1.5.xlsx',
  },
  {
    name: 'Coral Honeycomb Form',
    pdf: '/forms/M Coral Honeycomb Production Form V1.9.pdf',
    excel: '/forms/M Coral Honeycomb Production Form V1.9.xlsx',
  },
  {
    name: 'Drapery Form',
    pdf: '/forms/M Drapery Production Form V1.2.pdf',
    excel: '/forms/M Drapery Production Form V1.2.xlsx',
  },
  {
    name: 'Harbour Wood Form',
    pdf: '/forms/M Harbour Wood Production Form V1.3.pdf',
    excel: '/forms/M Harbour Wood Production Form V1.3.xlsx',
  },
  {
    name: 'Nautilus Pleated Form',
    pdf: '/forms/M Nautilus Pleated Production Form V1.2.pdf',
    excel: '/forms/M Nautilus Pleated Production Form V1.2.xlsx',
  },
  {
    name: 'Ocean Sheer Workorder',
    pdf: '/forms/M Ocean Sheer Workorder V1.5.pdf',
    excel: '/forms/M Ocean Sheer Workorder V1.5.xlsx',
  },
  {
    name: 'Oceanic Aluminum Form',
    pdf: '/forms/M Oceanic Aluminum Production Form V1.5.pdf',
    excel: '/forms/M Oceanic Aluminum Production Form V1.5.xlsx',
  },
  {
    name: 'Regatta Roller Shade Form',
    pdf: '/forms/M Regatta Roller Shade Production Form V1.4.pdf',
    excel: '/forms/M Regatta Roller Shade Production Form V1.4.xlsx',
  },
  {
    name: 'Specialty Shape Order Form',
    pdf: '/forms/M Specialty Shape Order Form V1.2.pdf',
    excel: '/forms/M Specialty Shape Order Form V1.2.xlsx',
  },
]

export default function Forms() {
  return (
    <>
      <div className="header px-2 md:px-5 lg:px-10">
        <div className="title">Order Forms</div>
      </div>
      <Nav />
      <div className="py-5 px-2 md:px-5 lg:px-10 pb-5">
        <div className="text-center text-gray-800 mb-2">
          If possible, please use the Microsoft Office Excel spreadsheet for
          orders.
          <br />
          If you do not have Microsoft Office please contact your Dealer
          Representative.
        </div>
        {forms.map(({ name, pdf, excel }, index) => (
          <DownloadItem
            index={index}
            name={name}
            pdf={pdf}
            excel={excel}
            className="max-w-lg"
          />
        ))}
      </div>
    </>
  )
}
