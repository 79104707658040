import React from 'react'
import { useLocation } from 'react-router-dom'

type Notification = {
  type: 'success' | 'danger'
  message: string
  location: string
}

type ContextType = {
  notifications: Notification[]
  setNotifications(notifications: Notification[]): void
  addNotification(
    notification: Omit<Notification, 'location'> & { location?: string },
  ): void
  removeNotification(notification: Notification): void
}

const noop = () => {
  // noop
}

const NotificationsContext = React.createContext<ContextType>({
  notifications: [],
  setNotifications: noop,
  addNotification: noop,
  removeNotification: noop,
})

export const NotificationsContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [notifications, setNotifications] = React.useState<Notification[]>([])
  const location = useLocation()

  React.useEffect(() => {
    setNotifications(
      notifications.filter((item) => item.location === location.pathname),
    )
  }, [location])

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        setNotifications,
        addNotification(notification) {
          setNotifications([
            ...notifications,
            {
              ...notification,
              location: notification.location || location.pathname,
            },
          ])
        },
        removeNotification(notification) {
          setNotifications(
            notifications.filter((item) => item !== notification),
          )
        },
      }}
      children={children}
    />
  )
}

export const useNotifications = () => React.useContext(NotificationsContext)
